import React from "react";
import styles from "../styles/LicenseLinkBar.module.css";
import coolicon from "/public/images/coolicon.svg";
import getImageSrc from "../lib/getImageSrc";

export default function LicenseLinkBar() {
  return (
    <div className={styles.linkBar}>
      <p className={styles.linkText}>
        Om de ZE-zones in te kunnen, komen er verschillende ontheffingen.
        Mogelijk kan je daar gebruik van maken.
      </p>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.opwegnaarzes.nl/vrijstellingen"
        className={styles.link}
      >
        <img alt="" width={16} height={16} src={getImageSrc(coolicon)} />
        Naar ontheffingsmogelijkheden
      </a>
    </div>
  );
}
