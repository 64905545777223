import React from "react";

import styles from "../styles/LicensePlateFormCard.module.css";
import Form from "./license-form";
import LicenseLinkBar from "./license-link-bar";
import LicenseSharingBar from "./license-sharing-bar";
import NavigationButton from "./navigation-button";

const START_TITLE = "Zero-emissie kentekencheck";
const START_MESSAGE =
  "Mag jouw <b>bestel- of vrachtwagen</b> vanaf 2025 de zero-emissiezones nog in? Check het aan de hand van je kenteken.";

export default function LicenseplateFormCard({ apiUrl }) {
  const [title, setTitle] = React.useState(START_TITLE);
  const [message, setMessage] = React.useState(START_MESSAGE);
  const [link, setLink] = React.useState();
  const [isAllowed, setAllowed] = React.useState("");
  const [licenseChecked, setLicenseChecked] = React.useState(false);
  const [checkedResult, setCheckedResult] = React.useState(false);

  const licenseTitleColor =
    isAllowed === "ok"
      ? styles.licensePlateOk
      : isAllowed === "nok"
      ? styles.licensePlateNotOk
      : "";
  const body = !licenseChecked ? (
    <Form onResponse={handleKentekenCheckResponse} apiUrl={apiUrl} />
  ) : (
    <NavigationButton
      text={"Nog een kenteken invoeren"}
      onClick={() => {
        setTitle(START_TITLE);
        setMessage(START_MESSAGE);
        setAllowed("");
        setLicenseChecked(false);
        setCheckedResult(false);
      }}
    ></NavigationButton>
  );

  const footer =
    licenseChecked &&
    (isAllowed !== "nok" ? (
      <LicenseSharingBar result={checkedResult} />
    ) : (
      <LicenseLinkBar />
    ));

  return (
    <section className={styles.card}>
      <h2 className={`${licenseTitleColor} ${styles.cardHeader}`}>{title}</h2>
      <p
        dangerouslySetInnerHTML={{ __html: message }}
        className={styles.licenseCheckMessage}
      ></p>
      <section className={styles.cardBody}>{body}</section>
      {footer}
    </section>
  );

  function handleKentekenCheckResponse(responseFromForm) {
    if (responseFromForm.code) {
      switch (responseFromForm.code) {
        case "NO_ZE_ZONE_REQUIRED_FOR_VEHICLE":
        case "VEHICLE_ALLOWED":
          setAllowed("ok");
          break;
        case "ALLOWED_WITH_EXEMPTION":
          setAllowed("nok");
          break;
        case "ALLOWED_TILL_31_12_2027":
        case "ALLOWED_TILL_31_12_2026":
        case "ALLOWED_TILL_31_12_2029":
        case "ALLOWED_DET_PLUS_13_YEARS":
          setAllowed("");
          break;
        default:
          setAllowed("nok");
          break;
      }
      setMessage(responseFromForm.message);
      setTitle(responseFromForm.allowance);
      setLink(responseFromForm.link);
      setLicenseChecked(true);
      setCheckedResult(responseFromForm);
    }
  }
}
