import React from "react";
import styles from "../styles/LicenseShareBar.module.css";
import whatsappLogo from "/public/images/whatsapp_logo.svg";
import emailLogo from "/public/images/email_logo.svg";
import coolIcon from "/public/images/coolicon.svg";
import getImageSrc from "../lib/getImageSrc";

export default function LicenseSharingBar(props) {
  const mailText = `mailto:?subject=&body=${encodeURIComponent(
    "Hallo! Op deze website kan je checken of je straks nog een zero-emissiezone in mag komen met je voertuig. Neem even een kijkje, dan weet je gelijk wat er voor jou gaat veranderen! www.opwegnaarzes.nl"
  )}`;
  const whatsappText = `https://wa.me/?text=${encodeURIComponent(
    "Hallo! Op deze website kan je checken of je straks nog een zero-emissiezone in mag komen met je voertuig. Neem even een kijkje, dan weet je gelijk wat er voor jou gaat veranderen! www.opwegnaarzes.nl"
  )}`;

  return (
    <>
      <div className={styles.sharingbar}>
        <p className={styles.sharingText}>
          Deel de Zero-emissie kentekencheck:
        </p>
        <div className={styles.sharingbarActions}>
          <a
            target="_parent"
            href={whatsappText}
            className={`${styles.iconButton}`}
          >
            <img
              alt="delen via Whatsapp"
              height={24}
              width={24}
              src={getImageSrc(whatsappLogo)}
            />
          </a>
          <a
            target="_parent"
            href={mailText}
            className={`${styles.iconButton}`}
          >
            <img
              alt="delen via e-mail"
              height={24}
              width={24}
              src={getImageSrc(emailLogo)}
            />
          </a>
        </div>
      </div>
      <div className={styles.sharingbarMoreInfo + " moreInfo"}>
        <p className={styles.sharingText}>Meer weten?</p>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.opwegnaarzes.nl/bedrijven"
          className={styles.link}
        >
          <img alt="" width={16} height={16} src={getImageSrc(coolIcon)} />
          Kijk op opwegnaarzes.nl
        </a>
      </div>
    </>
  );
}
