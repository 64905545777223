// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Locigw3eYmyKXAZHAQ3e {\n    color: #30A8DD;\n    cursor: pointer;\n\n    display: flex;\n    align-items: center;\n    gap: 0.5rem;\n}\n\n.wT8yR9tnDtciImVTiVZG {\n    margin-top: 2rem;\n}\n\n.BO8P1Fe99x7iZmH1GVAZ {\n    margin-bottom: .5rem;\n}\n", "",{"version":3,"sources":["webpack://./src/styles/LicenseLinkBar.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;;IAEf,aAAa;IACb,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,oBAAoB;AACxB","sourcesContent":[".link {\n    color: #30A8DD;\n    cursor: pointer;\n\n    display: flex;\n    align-items: center;\n    gap: 0.5rem;\n}\n\n.linkBar {\n    margin-top: 2rem;\n}\n\n.linkText {\n    margin-bottom: .5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "Locigw3eYmyKXAZHAQ3e",
	"linkBar": "wT8yR9tnDtciImVTiVZG",
	"linkText": "BO8P1Fe99x7iZmH1GVAZ"
};
export default ___CSS_LOADER_EXPORT___;
