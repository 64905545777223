import React from "react";
import ReactDOM from "react-dom";

import LicenseplateFormCard from "./components/licenseplate-form-card";

window.addEventListener("DOMContentLoaded", () => {
  const checkers = document.querySelectorAll(
    "[data-zes-kentekenchecker], .zes-kentekenchecker"
  );
  checkers.forEach((el) => {
    ReactDOM.render(
      <LicenseplateFormCard apiUrl="https://kentekencheck.opwegnaarzes.nl/api" />,
      el
    );
  });
});
