import * as yup from "yup";

const validPlatesRegex = [
  "^([A-Z]{2})([0-9]{2})([0-9]{2})$", // XX9999
  "^([0-9]{2})([0-9]{2})([A-Z]{2})$", // 9999XX
  "^([0-9]{2})([A-Z]{2})([0-9]{2})$", // 99XX99
  "^([BDFGHJKLMNPRSTVWXYZ]{2})([0-9]{2})([BDFGHJKLMNPRSTVWXYZ]{2})$", // XX99XX
  "^([BDFGHJKLMNPRSTVWXYZ]{2})([BDFGHJKLMNPRSTVWXYZ]{2})([0-9]{2})$", // XXXX99
  "^([0-9]{2})([BDFGHJKLMNPRSTVWXYZ]{2})([BDFGHJKLMNPRSTVWXYZ]{2})$", // 99XXXX
  "^([0-9]{2})([BDFGHJKLMNPRSTVWXYZ]{3})([0-9]{1})$", // 99XXX9
  "^([0-9]{1})([BDFGHJKLMNPRSTVWXYZ]{3})([0-9]{2})$", // 9XXX99
  "^([BDFGHJKLMNPRSTVWXYZ]{2})([0-9]{3})([BDFGHJKLMNPRSTVWXYZ]{1})$", // XX999X
  "^([BDFGHJKLMNPRSTVWXYZ]{1})([0-9]{3})([BDFGHJKLMNPRSTVWXYZ]{2})$", // X999XX
  "^([BDFGHJKLMNPRSTVWXYZ]{3})([0-9]{2})([BDFGHJKLMNPRSTVWXYZ]{1})$", // XXX99X 11
  "^([0-9]{1})([BDFGHJKMNPRSVWXYZ]{2})([0-9]{3})$", //9XX999 13
  "^([0-9]{3})([BDFGHJKMNPRSVWXYZ]{2})([0-9]{1})$", //999XX9 14
  "^([BDFGHJKLMNPRSTVWXYZ]{1})([0-9]{2})([BDFGHJKLMNPRSTVWXYZ]{3})$", //X99XXX V80DHD
];

const forbiddenCharacters =
  /^((?!GVD|KKK|KVT|LPF|NSB|PKK|PSV|TBS|SS|SD|PVV|SGP).){8}$/; // forbidden characters

const validator = yup
  .string()
  .required("Kenteken is verplicht")
  .test("valid-plate", "Ongeldig kenteken", function (value) {
    if (!value) return false;
    const clean = value.replace(/-/g, "").toUpperCase();
    const validRegex = validPlatesRegex.find((regex) => {
      const re = new RegExp(regex);
      const isValid = re.test(clean);
      return isValid;
    });

    if (validRegex) {
      const parsed = clean.replace(new RegExp(validRegex), "$1-$2-$3");
      return forbiddenCharacters.test(parsed);
    }

    return false;
  })
  .transform((value) => {
    const clean = value.replace(/-/g, "").toUpperCase();
    const validRegex = validPlatesRegex.find((regex) => {
      const re = new RegExp(regex);
      const isValid = re.test(clean);
      return isValid;
    });

    if (validRegex) {
      const parsed = clean.replace(new RegExp(validRegex), "$1$2$3");
      return parsed;
    }
    return value;
  });

export default validator;
