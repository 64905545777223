import React from "react";
import styles from "../styles/NavigationButton.module.css";
import trailingImage from "/public/images/trailing_icon.svg";
import getImageSrc from "../lib/getImageSrc";

export default function NavigationButton(props) {
  let icon = props.showLoading ? (
    <div className={styles.loadingSpinner}></div>
  ) : (
    <img alt="" src={getImageSrc(trailingImage)} width={16} height={16} />
  );

  return (
    <button
      id={props.id}
      className={styles.navigationButton}
      onClick={props.onClick}
      disabled={props.disabled}
      type={props.type}
    >
      <div className={styles.content}>
        <p className={styles.text}>{props.text}</p>
        {icon}
      </div>
    </button>
  );
}
