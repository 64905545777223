import { Formik } from "formik";
import { useState } from "react";
import * as yup from "yup";

import styles from '../styles/LicenseForm.module.css';

import NavigationButton from "./navigation-button";

import licenseplateValidator from "../lib/validation/licenseplate";

export default function Form({ onResponse, apiUrl = '/api' }) {
  const [errorMessage, setErrorMessage] = useState(null);

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async function (values, bag) {
        
        try {
          const res = await fetch(`${apiUrl}/check/${values.licenseplate}`);

          if (res.status === 404) {
            setErrorMessage("Kenteken niet gevonden");
          }
          else {
            const data = await res.json();
            data['licenseplate'] = values.licenseplate;
            onResponse(data);
          }
        } catch (error) {
          console.error("er ging iets mis", error);
        } finally {
          bag.setSubmitting(false);
        }
      }}
      initialValues={{
        licenseplate: "",
      }}
      validationSchema={yup.object({
        licenseplate: licenseplateValidator,
      })}
    >
      {(form) => 
      (
          <form className={styles.licenseForm} onSubmit={form.handleSubmit}>
            <div>
              <div className={styles.licensePlateInputFieldContainer}>
                <p className={styles.countryCodePart}>NL</p>
                <input name="licenseplate" aria-describedby="licenseplate-description" maxLength={8} className={styles.licensePlateInputField} onChange={e => {
                  setErrorMessage(null)
                  form.handleChange(e)
                }} onBlur={form.handleBlur} />
                <div id="licenseplate-description" className={styles.srOnly}>Vul hier je kenteken in</div>
              </div>
              <p className={styles.formErrorMessage}>{errorMessage || form?.errors?.licenseplate}</p>
            </div>

            <div>
              <NavigationButton showLoading={form.isSubmitting} text={'Check mijn kenteken'} disabled={form.isSubmitting} type="submit"></NavigationButton>
            </div>
          </form>
        )
      }
    </Formik>
  );
}
